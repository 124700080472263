import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import RustPricing from '../components/PricingPlans/RustPricing';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

import RustDetailsImage from "../assets/images/jeux/rust-details.jpg";
import RustDetailsImage2 from "../assets/images/jeux/rust-details2.jpg";

const serviceImgStyle = {
    maxHeight: '500px',
    maxWidth: '100%',
    borderRadius: '10%',
 }

const ServiceDetails = () => (
    <Layout>
        <SEO title="Serveur Rust" /> 

        <Navbar />

        <PageBanner pageTitle="Serveur Rust | Tahiti" />

        <div className="services-details-area ptb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 services-details">
                        <div className="services-details-desc">
                            <h3>🎉 Lance ton serveur Rust ! 🎉</h3>
                            <p>Nous sommes ravis de vous présenter notre service d'hébergement de serveurs Rust, une solution sur-mesure pour les passionnés de ce jeu de survie captivant et intense. Découvrez comment FenuaHosting peut transformer votre expérience de jeu grâce à nos serveurs puissants, notre latence extrêmement faible et notre support clientèle inégalé.</p>

                            <h6>🚀 Des serveurs ultra-puissants jusqu'à 4,7 GHz ! 🚀</h6>
                            <p>Chez FenuaHosting, nous mettons tout en œuvre pour vous offrir une expérience de jeu incomparable. Nos serveurs Rust sont équipés de processeurs ultra-puissants allant jusqu'à 4,7 GHz, assurant des performances optimales pour une immersion totale dans l'univers impitoyable de Rust. Dites adieu aux lags et aux temps de chargement, car avec FenuaHosting, le plaisir de jouer est garanti !</p>
                        
                            <h6>⚡ Une latence ultra-faible pour des parties fluides ! ⚡</h6>
                            <p>Nous savons combien une latence élevée peut gâcher votre expérience de jeu. C'est pourquoi nous avons développé une infrastructure de pointe pour garantir une latence extrêmement faible. Avec FenuaHosting, vos parties de Rust seront plus fluides que jamais, vous permettant de vous concentrer sur votre survie et votre domination.</p>
                            <p></p>
                        </div>
                    </div>

                    <div className="col-lg-6 services-details-image">
                        <img 
                            style={serviceImgStyle}
                            src={RustDetailsImage} 
                            className="animate__animated animate__fadeInUp" 
                            alt="image" 
                        />
                    </div>
                </div>

                <div className="separate"></div>

                <div className="row align-items-center">
                    <div className="col-lg-6 services-details-image">
                        <img 
                            style={serviceImgStyle}
                            src={RustDetailsImage2} 
                            className="animate__animated animate__fadeInUp" 
                            alt="image" 
                        />
                    </div>

                    <div className="col-lg-6 services-details">
                        <div className="services-details-desc">
                            <h6>🎮 Un panel de gestion intuitif pour un contrôle total ! 🎮</h6>
                            <p>Notre panel de gestion dédié aux serveurs Rust vous offre un contrôle total sur votre serveur. Depuis une interface conviviale et facile à utiliser, gérez les plugins, modifiez les paramètres de votre serveur, effectuez des sauvegardes et bien plus encore. Ne perdez plus de temps à chercher comment administrer votre serveur Rust, FenuaHosting s'occupe de tout !</p>

                            <div className="services-details-accordion">
                                <Accordion allowZeroExpanded preExpanded={['a']}>
                                    <AccordionItem uuid="a">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                    Est-il possible d'installer un mods custom ?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Absolument ! Avec votre panel utilisateur, vous pouvez installer des mods custom.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="b">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                    Quelles sont les versions prises en charge ?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Nous prenons en charge toutes les versions Rust dont oxyde.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="c">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                    Limite de slot ?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Non, nous ne limitons pas les slots. Vous pouvez vous-même modifier le nombre de slot acceptés par votre serveur. Seules les ressources sont limitées en fonction de votre abonnement.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <RustPricing />

        <Footer />
    </Layout>
)

export default ServiceDetails;