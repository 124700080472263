import React, { Component } from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';
import AgencyShape1 from "../../assets/images/agency-image/agency-shape1.png";

class RustPricing extends Component {

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabs_item");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByTagName("li");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("current", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += "current";
    }

    render() {
        return (
            <div className="pricing-area pb-50">
                <div className="container">
                    <div className="section-title st-fs-28">
                        <span className="sub-title">Tarifs</span>
                        <h2>Tarifs</h2>
                        <div className="bar"></div>
                        <p>Retrouvez ci-dessous nos différents abonnements.</p>
                    </div>
                    
                    <div className="tab pricing-tab bg-color">
                        <ul className="tabs" style={{display: 'none'}}>
                            <li
                                className="current"
                                onClick={(e) => this.openTabSection(e, 'tab1')}
                            >
                                Mensuel
                            </li>
                            
                            <li onClick={(e) => this.openTabSection(e, 'tab2')}>
                                Annuel <i data-feather="calendar"></i>
                            </li>
                        </ul>

                        <div className="tab_content">
                            <div id="tab1" className="tabs_item">
                                <div className="row justify-content-center">

                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="pricing-box">
                                            <div className="pricing-header">
                                                <h3>Pro</h3>
                                                <p>Conseillé pour <br /> 4-20 personnes</p>
                                            </div>

                                            <div className="price">
                                                2990 XPF <span>/m</span>
                                            </div>

                                            <div className="buy-btn">
                                                <Link to="https://fenuahosting.com/dashboard/order/main/packages/rust/?group_id=3" className="btn btn-primary">
                                                    Lancez votre serveur Pro !
                                                </Link>
                                            </div>

                                            <ul className="pricing-features">
                                                <li><Icon.Check />Toute version</li>
                                                <li><Icon.Check />5Go RAM</li>
                                                <li><Icon.Check />6Go disque NVME</li>
                                                <li><Icon.Check />Accès Panel</li>
                                                <li><Icon.Check />Mods custom</li>
                                                <li><Icon.Check />1 CPU</li>
                                                <li><Icon.Check />Sauvegarde auto</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="pricing-box">
                                            <div className="pricing-header">
                                                <h3>Premium</h3>
                                                <p>Conseillé pour <br /> +20 personnes</p>
                                            </div>

                                            <div className="price">
                                                5550 XPF <span>/m</span>
                                            </div>

                                            <div className="buy-btn">
                                                <Link to="https://fenuahosting.com/dashboard/order/main/packages/rust/?group_id=3" className="btn btn-primary">
                                                    Lancez votre serveur Premium !
                                                </Link>
                                            </div>

                                            <ul className="pricing-features">
                                                <li><Icon.Check />Toute version</li>
                                                <li><Icon.Check />8Go RAM</li>
                                                <li><Icon.Check />8Go disque NVME</li>
                                                <li><Icon.Check />Accès Panel</li>
                                                <li><Icon.Check />Mods custom</li>
                                                <li><Icon.Check />2 CPU boosté 4.7GHz</li>
                                                <li><Icon.Check />Sauvegarde auto</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Shape Image */}
                <div className="shape9 mobile-dnone">
                    <img src={AgencyShape1} alt="image" />
                </div>
            </div>
        );
    }
}

export default RustPricing;